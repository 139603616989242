import React, { useRef, useState, useContext, useEffect, Component } from 'react';
import moment from "moment";
import uuid from "react-uuid";

import { useHistory } from "react-router-dom";

import {
  Box,
  Grid,
  Divider,
  IconButton,
  Tooltip,
  Hidden,
} from "@material-ui/core";

import Icon from "@material-ui/core/Icon";

import { ReactComponent as CalendarIcon } from "../../assets/img/icons/calendar.svg";
import { ReactComponent as TimeIcon } from "../../assets/img/icons/time.svg";
import { ReactComponent as EditIcon } from "../../assets/img/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/img/icons/delete.svg";

import Button from '@material-ui/core/Button';
import CustomLabel from "../../components/CustomFields/CustomLabel.jsx";
import CustomButton from "../../components/CustomFields/CustomButton.jsx";
import CustomInput from "../../components/CustomFields/CustomInput.jsx";
import CustomSelectOption from "../../components/CustomFields/CustomSelectOption.jsx";
import CustomDialog from "../../components/Dialog/CustomDialog.jsx";
import CustomFormDialog from "../../components/Dialog/CustomFormDialog.jsx";
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { CKEditor } from "ckeditor4-react";
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import wmsApi from "../../api/wmsApi.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import apiUtil from '../../api/apiUtil.jsx';

import logo from '../../assets/img/logo_text_white.png';


export default function PublicJobsForm() {
  let history = useHistory();

  const { setPublicMode } = useContext(AuthContext);

  const accessToken = '20a0cfe946b70960a265b6ec93fcd61efbf68b9bc51f778501d150796973656d';

  const [validatedSite, setValidatedSite] = useState(false);
  const [errorSite, setErrorSite] = useState(null);

  const [validatedDropOffDate, setValidatedDropOffDate] = useState(false);
  const [validatedDropOffTime, setValidatedDropOffTune] = useState(false);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const isMounted = useRef(null);
  const [customerIdInput, setCustomerIdInput] = useState("");
  const [customerNameInput, setCustomerNameInput] = useState("");
  const [siteInput, setSiteInput] = useState("");
  const [dropOffDateInput, setDropOffDateInput] = useState(null);
  const [dropOffTimePlannedInput, setDropOffTimePlannedInput] = useState(null);
  const [dropOffDescriptionInput, setDropOffDescriptionInput] = useState("<html><head><title></title></head><body><p>Job Ref:<br />FROM:<br />PTC:<br />TO:<br />PTC:<br />Items:</p></body></html>");
  const [contactPersonInput, setContactPersonInput] = useState("");
  const [contactEmailInput, setContactEmailInput] = useState("");
  const [contactNumberInput, setContactNumberInput] = useState("");
  const [departmentItems, setDepartmentItems] = useState([]);
  const [departmentItem, setDepartmentItem] = useState([]);
  const [departmentId, setDepartmentId] = useState("");
  const [picItems, setPicItems] = useState([]);

  const axiosRef = useRef();

  useEffect(() => {
    //setPublicMode(true);
    // executed when component mounted
    callCustomerReadApi();
    isMounted.current = true;
    return () => {
      // executed when unmount
      isMounted.current = false;
      axiosRef.current.cancel();
    }
  }, []);

  const callCustomerReadApi = async e => {
    const params = new URLSearchParams(window.location.search)
    let uuid = params.get('id');

    if (uuid) {
      let id = atob(uuid);
      setIsLoading(true);
      wmsApi.read('customers/' + id, null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            setIsLoading(false);
            if (data && data.customer) {
              setCustomerIdInput(data.customer.id);
              setCustomerNameInput(data.customer.company_name);

              let departmentItems = [];
              if (
                data.customer &&
                data.customer.departments &&
                data.customer.departments.length > 0
              ) {
                departmentItems = data.customer.departments.map(
                  (item, i) => {
                    return {
                      value: item.id,
                      label: item.name,
                      item: item,
                    };
                  }
                );
              }

              setDepartmentItems(departmentItems);

              let departmentItem = null;
              let department_id =
                data && data.department_id ? data.department_id : "";
              let departmentArray =
                departmentItems && departmentItems.length > 0
                  ? departmentItems.filter(
                    (x) => x.value === department_id
                  )
                  : [];
              if (departmentArray && departmentArray.length > 0) {
                departmentItem = departmentArray[0].item;
              }
              setDepartmentId(department_id);
              setDepartmentItem(departmentItem);
            }
          }, (error, type) => {
            setIsLoading(false);
          });
        });
    } else {
      setError({
        title: 'Invalid customer'
      }
      );
      setIsDisabled(true);
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();

    if (!isDisabled) {
      const form = e.currentTarget;
      setError(null);

      if (form.checkValidity() === true) {
        let job_steps = [{
          job_step_name: "Delivery",
          job_step_status_id: 1,
          location: siteInput,
          is_signature_required: true,
          is_scan_required: false,
          job_step_pic: contactPersonInput,
          job_step_pic_contact: contactNumberInput,
          description: "",
          remove: false,
          order_sequence: 1
        }];

        let drop_off_date = moment(dropOffDateInput);
        let drop_off_time_planned = "";
        if (
          dropOffTimePlannedInput &&
          dropOffTimePlannedInput !== ""
        ) {
          drop_off_time_planned = moment(
            drop_off_date.format(apiUtil.getDefaultDateFormat()) +
            " " +
            dropOffTimePlannedInput
          )
            .local()
            .format("YYYY-MM-DD HH:mm:ss");
        }

        let dropOffDescription;
        let isIncludePh = dropOffDescriptionInput ? dropOffDescriptionInput.indexOf("ph:") : -1;
        dropOffDescription = dropOffDescriptionInput;
        if (isIncludePh != -1) {
          let isIncludeWA = 0;
          var idx = 0;
          do {
            isIncludePh = dropOffDescription.indexOf("ph:", idx);
            isIncludeWA = dropOffDescription.indexOf(`ph:<a`, idx);

            if (isIncludePh == -1) {
              break;
            }
            if (isIncludeWA == -1) {
              const getPhNumber = dropOffDescription.slice(isIncludePh + 3, isIncludePh + 11);
              dropOffDescription = dropOffDescription.replace('ph:' + getPhNumber, `ph:<a href="https://wa.me/+65${getPhNumber}">${getPhNumber}</a>`);
              isIncludePh = dropOffDescription.indexOf(`ph:<a`, idx);
            }
            idx = isIncludePh + 48;
            if (isIncludePh == -1) {
              break;
            }
          }
          while (idx < dropOffDescription.length);
        }

        let drop_off_name = "";
        let drop_off_contact_no = "";
        let notification_email = "";

        if (picItems && picItems.length > 0) {
          drop_off_name = picItems
            .filter((x) => !x.remove)
            .map((item, i) => {
              return item.drop_off_name;
            })
            .join(",");

          drop_off_contact_no = picItems
            .filter((x) => !x.remove)
            .map((item, i) => {
              return item.drop_off_contact_no;
            })
            .join(",");

          notification_email = picItems
            .filter((x) => !x.remove)
            .map((item, i) => {
              return item.notification_email;
            })
            .join(",");
        } else {
          drop_off_name = contactPersonInput;
          drop_off_contact_no = contactNumberInput;
          notification_email = contactEmailInput;
        }

        let request = {
          job_steps: job_steps,
          is_notify_customer: true,
          drop_off_date: dropOffDateInput,
          drop_off_time_planned: drop_off_time_planned,
          drop_off_description: dropOffDescription,
          customer_id: customerIdInput,
          drop_off_contact_name: customerNameInput,
          notification_email: notification_email,
          drop_off_contact_no: drop_off_contact_no,
          drop_off_name: drop_off_name,
          drop_off_address: siteInput,
          department_id: departmentId,
          // Hard coded to Not Assigned status
          order_status_id: 967,
          order_source_id: 6
        };

        setIsLoading(true);
        //console.log(request);
        ldsApi.create("orders", request, accessToken).then((result) => {
          apiUtil.parseResult(
            result,
            (data) => {
              apiUtil.toast("Successfully Created", "check_circle");
              setTimeout(function() {
                setIsLoading(false);
                setSuccess({
                  title: "Successfully Created"
                });
                setSiteInput("");
                setDropOffDateInput(null);
                setDropOffTimePlannedInput(null);
                setDropOffDescriptionInput("<html><head><title></title></head><body><p>Job Ref:<br />FROM:<br />PTC:<br />TO:<br />PTC:<br />Items:</p></body></html>");
                setContactPersonInput("");
                setContactEmailInput("");
                setContactNumberInput("");
                window.scrollTo(0, 0);
                window. location. reload(); 
              }.bind(this), 2000)
            },
            (error, type) => {
              setIsLoading(false);
              apiUtil.toast(error, 'warning', 'error');
              setError({
                title: error
              }
              );
            }
          );
        });
      } else {
        apiUtil.toast("Please fill all the required fields", 'warning', 'error');
        setError({
          title: "Please fill all the required fields"
        });
      }
    }
  };

  const setHeader = () => {
    return (
      <Box pt={1} pb={1} className={"custom-grid-header"}>
        <Grid container alignItems={"center"}>
          <Box clone pl={{ xs: 0, md: 2 }}>
            <Grid item xs={12} md={4}>
              <CustomLabel label={"Name"} />
            </Grid>
          </Box>
          <Box clone>
            <Grid item xs={12} md={4}>
              <CustomLabel label={"Email"} />
            </Grid>
          </Box>
          <Box clone>
            <Grid item xs={12} md={4}>
              <CustomLabel label={"Phone No."} />
            </Grid>
          </Box>
          {/* <Box clone pr={{ xs: 0, md: 2 }} textAlign={"right"}>
            <Grid item xs={12} md={3}>
              <CustomLabel label={"Actions"} />
            </Grid>
          </Box> */}
        </Grid>
      </Box>
    );
  };
  /* DIALOG */
  // const editDialog = () => {
  //   return (
  //     <CustomFormDialog
  //       open={this.state.openDialogEdit}
  //       title={this.state.itemDialogEdit !== null ? "Edit PIC" : "Add PIC"}
  //       onClose={() => {
  //         this.setState({
  //           openDialogEdit: false,
  //           itemDialogEdit: null,
  //         });
  //       }}
  //     >
  //     </CustomFormDialog>
  //   );
  // };

  // const deleteDialog = () => {
  //   return (
  //     <CustomDialog
  //       open={this.state.openDialogDelete}
  //       title={"Delete"}
  //       onClose={() => {
  //         this.setState({
  //           openDialogDelete: false,
  //           itemDialogDelete: null,
  //         });
  //       }}
  //       onOk={() => {
  //         let item = this.state.itemDialogDelete;
  //         this.remove(item.uuid, () => {
  //           this.setState({
  //             openDialogDelete: false,
  //             itemDialogDelete: null,
  //           });
  //         });
  //       }}
  //     >
  //       <Box>Are you sure you want to delete?</Box>
  //     </CustomDialog>
  //   );
  // };
  /* END DIALOG */

  return (
    <div className="pub-job-form container">
      <div className="job-form" component="main">
        <div className="form-wrapper">
          <div className="logo"><img src={logo} alt="" /></div>
          <form noValidate onSubmit={handleSubmit}>

            <Box textAlign="center" mb={3}>
              <div className="form-title">
                Expeditor Job Form
              </div>
              <div className="form-description">
              </div>
              {
                error && !isLoading
                &&
                <Box textAlign="left" mt={3} mb={3}>
                  <Alert severity="error">
                    {error.title && <b>{error.title}</b>}
                    {(error.errors && error.errors.length > 0) && error.errors.map((e, i) => {
                      return <div key={i}>{e}</div>
                    })}
                  </Alert>
                </Box>
              }
              {
                success && !isLoading
                &&
                <Box textAlign="left" mt={3} mb={3}>
                  <Alert severity="success">
                    {success.title && <b>{success.title}</b>}
                  </Alert>
                </Box>
              }

              <CustomInput
                disabled={isLoading || isDisabled}
                label={"Customer"}
                value={customerNameInput}
                readOnly={true}
              />

              <CustomInput
                disabled={isLoading || isDisabled}
                label={"Place of Delivery"}
                value={siteInput}
                required={true}
                onChange={(e) => {
                  setSiteInput(e.target.value);
                }}
              />

              <CustomInput
                disabled={isLoading || isDisabled}
                label={"Date of Delivery"}
                placeholder={"DD/MM/YYYY"}
                color={"secondary"}
                type={"date"}
                endAdornment={(obj) => {
                  return (
                    <Box
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      <CalendarIcon />
                    </Box>
                  );
                }}
                value={dropOffDateInput}
                error={validatedDropOffDate}
                required={true}
                onChange={(e) => {
                  setDropOffDateInput(e.target.value)
                }}
              />

              <CustomInput
                disabled={isLoading || isDisabled}
                label={"Time of Delivery"}
                placeholder={"-- : --"}
                type={"time"}
                color={"secondary"}
                endAdornment={(obj) => {
                  return (
                    <Box
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      <TimeIcon />
                    </Box>
                  );
                }}
                value={dropOffTimePlannedInput}
                onChange={(e) => {
                  setDropOffTimePlannedInput(e.target.value)
                }}
              />

              <CustomLabel
                className={"custom-label"}
                label={"Job Description"}
              />
              <CKEditor
                allowedContent={true}
                initData={dropOffDescriptionInput}
                name="htmlEditor"
                type="classic"
                config={{
                  versionCheck: false,
                  fullPage: true,
                  //allowedContent: true,
                  //forcePasteAsPlainText: true,
                  height: 290,
                  autoUpdateElement: true,
                  removePlugins: "elementspath,image",
                  resize_enabled: false,
                  extraAllowedContent: "img",
                  extraPlugins:
                    "colorbutton,justify,image2,uploadimage",
                  removeDialogTabs: "image:advanced;link:advanced",
                  toolbarGroups: [
                    {
                      name: "document",
                      groups: ["mode", "doctools", "document"],
                    },
                    {
                      name: "clipboard",
                      groups: ["clipboard", "undo"],
                    },
                    {
                      name: "editing",
                      groups: [
                        "find",
                        "selection",
                        "editing",
                      ],
                    },
                    { name: "forms", groups: ["forms"] },
                    {
                      name: "basicstyles",
                      groups: ["basicstyles", "cleanup"],
                    },
                    { name: "links", groups: ["links"] },
                    { name: "insert", groups: ["insert"] },
                    { name: "colors", groups: ["colors"] },
                    { name: "others", groups: ["others"] },
                    { name: "about", groups: ["about"] },
                    {
                      name: "paragraph",
                      groups: [
                        "list",
                        "indent",
                        "blocks",
                        "align",
                        "bidi",
                        "paragraph",
                      ],
                    }
                  ],
                  removeButtons:
                    "Source,Cut,Copy,Paste,PasteText,PasteFromWord,Replace,Language,BidiRtl,BidiLtr,Anchor,Flash,SpecialChar,About,Blockquote,BGColor",
                }}
                onChange={(e) =>
                  setDropOffDescriptionInput(e.editor.getData())
                }
              />

              <CustomSelectOption
                label={"Department"}
                placeholder={"Select department"}
                value={departmentId}
                items={departmentItems}
                onChange={(value, item) => {
                  setDepartmentId(value);
                  setDepartmentItem(item);

                  let picItems = [];
                  if (
                    item &&
                    item.item &&
                    item.item.contact_people &&
                    item.item.contact_people.length > 0
                  ) {
                    picItems = item.item.contact_people.map(
                      (item, i) => {
                        return {
                          uuid: uuid(),
                          id: item.id,
                          drop_off_name: item.first_name,
                          notification_email: item.email,
                          drop_off_contact_no: item.contact_no,
                          remove: false,
                        };
                      }
                    );
                  }

                  console.log(picItems);
                  setPicItems(picItems);
                }}
              />

              <Box pt={5} pb={5}>
                <Grid container>
                  <Box clone pb={1} textAlign={"left"}>
                    <Grid item xs={12}>
                      <CustomLabel
                        className={"custom-label"}
                        label={"Person In Charge"}
                      />
                      <Divider light />
                    </Grid>
                  </Box>
                  <Grid item xs={12}>
                    <Hidden xsDown implementation="css">
                      {setHeader()}
                    </Hidden>
                    <Box>
                      {picItems &&
                        picItems.length > 0 &&
                        picItems.filter((x) => !x.remove).length > 0 ? (
                        picItems
                          .filter((x) => !x.remove)
                          .map((item, i) => {
                            return (
                              <Box key={i} pt={1} pb={1}>
                                <Grid container alignItems={"center"}>
                                  <Box clone pl={{ xs: 0, md: 2 }}>
                                    <Grid item xs={12} md={4}>
                                      <CustomLabel
                                        label={
                                          <Box>
                                            <Tooltip title={"Name"} placement={"top"}>
                                              <Box>{item.drop_off_name}</Box>
                                            </Tooltip>
                                          </Box>
                                        }
                                      />
                                    </Grid>
                                  </Box>
                                  <Box clone>
                                    <Grid item xs={12} md={4}>
                                      <CustomLabel
                                        label={
                                          <Box>
                                            <Tooltip title={"Email"} placement={"top"}>
                                              <Box>{item.notification_email}</Box>
                                            </Tooltip>
                                          </Box>
                                        }
                                      />
                                    </Grid>
                                  </Box>
                                  <Box clone>
                                    <Grid item xs={12} md={4}>
                                      <CustomLabel
                                        label={
                                          <Box>
                                            <Tooltip
                                              title={"Phone No."}
                                              placement={"top"}
                                            >
                                              <Box>{item.drop_off_contact_no}</Box>
                                            </Tooltip>
                                          </Box>
                                        }
                                      />
                                    </Grid>
                                  </Box>
                                </Grid>
                                <Divider light />
                              </Box>
                            );
                          })
                      ) : (
                        <Grid container>
                          <Box clone pt={3} textAlign={"center"}>
                            <Grid item xs={12}>
                              <CustomLabel label={"No Results!"} weight={"bold"} />
                            </Grid>
                          </Box>
                        </Grid>
                      )}

                      {/* <Grid container>
                        <Box clone>
                          <Grid item xs={12}>
                            {!this.state.disabled && (
                              <CustomButton
                                disabled={this.state.disabled}
                                color={"secondary"}
                                href={"/"}
                                onClick={(e) => {
                                  e.preventDefault();

                                  this.setState({
                                    openDialogEdit: true,
                                    itemDialogEdit: null,
                                  });
                                }}
                              >
                                <Icon component={"i"}>add</Icon>
                                <Box display={"inline-block"} pl={1}>
                                  Add PIC
                                </Box>
                              </CustomButton>
                            )}
                          </Grid>
                        </Box>
                      </Grid> */}
                    </Box>
                  </Grid>
                </Grid>
                {/* {editDialog()}
                {deleteDialog()} */}
              </Box>

              {/* <CustomInput
                disabled={isLoading || isDisabled}
                label={"Contact Person"}
                value={contactPersonInput}
                required={true}
                onChange={(e) => {
                  setContactPersonInput(e.target.value);
                }}
              />

              <CustomInput
                disabled={isLoading || isDisabled}
                label={"Contact Email"}
                value={contactEmailInput}
                required={true}
                onChange={(e) => {
                  setContactEmailInput(e.target.value);
                }}
              />

              <CustomInput
                disabled={isLoading || isDisabled}
                label={"Contact Number"}
                value={contactNumberInput}
                required={true}
                onChange={(e) => {
                  setContactNumberInput(e.target.value);
                }}
              /> */}
            </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLoading || isDisabled}
            >
              <Box pl={2} pr={2} style={{ color: 'white' }}>SUBMIT</Box>
              {isLoading && <CircularProgress size={15} style={{ color: 'white' }} />}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}